import { Colors } from '@dermloop/ui/util';
import styled from 'styled-components';

interface HistoryLineProps {
  isVerticallyAligned: boolean;
  isLastElement: boolean;
  color?: string;
  position?: string;
}

const StyledHistoryLine = styled.div<{
  isVerticallyAligned: boolean;
  position: string;
}>`
  display: flex;
  flex-flow: ${(props) => (props.isVerticallyAligned ? 'column' : 'row')};
  width: ${(props) => (props.isVerticallyAligned ? '' : '100%')};
  position: ${(props) =>
    props.isVerticallyAligned || props.position ? '' : 'absolute'};
  bottom: ${(props) => (props.isVerticallyAligned ? '' : '0')};
  right: ${(props) => (props.isVerticallyAligned ? '' : '0')};
  left: ${(props) => (props.isVerticallyAligned ? '' : '2rem')};
  padding-bottom: ${(props) => (props.isVerticallyAligned ? '' : '1rem')}; ;
`;

const Dot = styled.div<{ color: string }>`
  background-color: ${(props) =>
    props.color ? props.color : Colors.BRAND_BROWN_DARK};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0.3rem;
`;

const Line = styled.div<{ isVerticallyAligned: boolean; color: string }>`
  background-color: ${(props) =>
    props.color ? props.color : Colors.BRAND_GRAY_MEDIUM};
  margin: auto;
  width: ${(props) => (props.isVerticallyAligned ? '2px' : '100%')};
  height: ${(props) => (props.isVerticallyAligned ? '100%' : '2px')};
`;

const DottedLine = styled.div<{ isVerticallyAligned: boolean; color: string }>`
  margin: auto;
  color: ${(props) => (props.color ? props.color : Colors.BRAND_GRAY_MEDIUM)};
  height: ${(props) => (props.isVerticallyAligned ? '100%' : '')};
  width: ${(props) => (props.isVerticallyAligned ? '' : '100%')};
  border-right: ${(props) => (props.isVerticallyAligned ? '2px dotted' : '')};
  border-top: ${(props) => (props.isVerticallyAligned ? '' : '2px dotted')};
  margin-left: ${(props) => (props.isVerticallyAligned ? '' : '0.25rem')};
  margin-top: ${(props) => (props.isVerticallyAligned ? '0.25rem' : '')}; ;
`;

export function HistoryLine(props: HistoryLineProps) {
  return (
    <StyledHistoryLine
      isVerticallyAligned={props.isVerticallyAligned}
      position={props.position}
    >
      <Dot color={props.color}></Dot>
      {!props.isLastElement ? (
        <Line
          isVerticallyAligned={props.isVerticallyAligned}
          color={props.color}
        ></Line>
      ) : (
        <DottedLine
          isVerticallyAligned={props.isVerticallyAligned}
          color={props.color}
        ></DottedLine>
      )}
    </StyledHistoryLine>
  );
}

export default HistoryLine;
