import { ReactNode } from 'react';
import { LayoutRectangle } from 'react-native';
import FormControlGroup from '../form-control-group/form-control-group';

/* eslint-disable-next-line */
export interface ClinicalDetailControlGroupProps<T> {
  title: string;
  subtitle?: string;
  children: ReactNode;
  value: T;
  mandatory?: boolean;
  onToggle?: () => void;
  open: boolean;
  previewValue?: string;
  onLayout?: (layout: LayoutRectangle) => void;
  rightSideHeaderContent?: JSX.Element;
}

export function ClinicalDetailControlGroup<T>(
  props: ClinicalDetailControlGroupProps<T>
) {
  return (
    <FormControlGroup
      title={props.title}
      subtitle={props.subtitle}
      openedIcon={props.value != null ? 'SegueUp' : null}
      mandatory={props.mandatory}
      shadow
      showCollapseButton={props.value != null}
      onCollapse={() => props.onToggle()}
      onPress={() => (props.value != null ? props.onToggle() : null)}
      open={props.open}
      previewValue={props.previewValue}
      rightSideHeaderContent={props.rightSideHeaderContent}
      onLayout={(layout) => props.onLayout && props.onLayout(layout)}
    >
      {props.children}
    </FormControlGroup>
  );
}

export default ClinicalDetailControlGroup;
